<script setup lang="ts">
const wpDomain: string = import.meta.env.VITE_APP_WP_DOMAIN;
import type { LocaleObject } from "vue-i18n-routing";
const { locale, locales } = useI18n();
import { useI18nStore } from "@/store/i18n";
const switchLocalePath = useSwitchLocalePath();

const i18nStore = useI18nStore();
const { t } = useI18n();

const availableLocales = computed<LocaleObject[]>(() => {
  return locales.value.filter((i): i is LocaleObject => {
    return typeof i !== "string" && i.code !== locale.value;
  });
});

const changeLocale = (code: string) => {
  i18nStore.setLocale(code);
  navigateTo(switchLocalePath(code));
};
</script>

<template>
  <Toolbar class="app-header">
    <template #start>
      <NuxtLink :to="wpDomain" target="_blank" class="flex">
        <img class="app-logo" src="@/assets/images/Logo.svg" />
      </NuxtLink>
    </template>
    <template #end>
      <NuxtLink :to="wpDomain" class="nav-wp-button hidden lg:block">
        {{ t("returnToWP") }}
      </NuxtLink>
      <button v-for="lang in availableLocales" :key="lang.code" @click="changeLocale(lang.code)">
        <div class="flag" :class="['flag--' + lang.code]"></div>
      </button>
    </template>
  </Toolbar>
</template>

<style lang="scss" scoped>
.app-header {
  border: none;
  background: white !important;
  border-radius: 16px;
  padding: 16px 20px;
  margin-bottom: 12px;
  @media screen and (min-width: $web-width) {
    padding: 12px 32px;
    margin-bottom: 28px;
  }
}

.app-logo {
  height: 24px;
  @media screen and (min-width: $web-width) {
    height: unset;
  }
}
@function halfs($first, $second, $or: -180deg) {
  @return linear-gradient($or, $first 50%, $second 50%);
}

.flag {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid $disabled;
  &--pl {
    background: halfs(white, red);
  }
  &--ua {
    background: halfs(#4489bb, #ffda44);
  }
}
.nav-wp-button {
  color: $primary;
  border: 1px solid $primary;
  padding: 6px 16px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  margin-right: 24px;
}
</style>
